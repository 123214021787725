import { Link } from 'gatsby';
import React from 'react';
import P from '~components/typography/P';

const DeleteAppUser: React.FC = () => {
  return (
    <>
      <form name="user-delete" method="POST" data-netlify="true" action="/nachricht-gesendet" className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <input type="hidden" name="form-name" value="user-delete" />
        <div>
          <label htmlFor="first_name" className="block text-sm font-medium ">
            Name
          </label>
          <div className="mt-1">
            <input type="text" name="Vorname" id="vorname" autoComplete="given-name" className="block w-full border border-black px-4 py-3 shadow-sm focus:border-brand focus:ring-brand" required />
          </div>
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            E-Mail mit der Ihr App Account verknüpft ist
          </label>
          <div className="mt-1">
            <input id="email" name="E-Mail" type="email" autoComplete="email" className="block w-full border border-black px-4 py-3 shadow-sm focus:border-brand focus:ring-brand" required />
          </div>
        </div>

        <div className="sm:col-span-2 sm:flex">
          <P className="text-xs">
            Wir respektieren Dein Vertrauen und behandeln Deine Daten mit Respekt. Mit dem Absenden dieses Formulars akzeptierst Du unsere{' '}
            <Link to="/app/rechtliches/datenschutz/" className="font-medium underline">
              Datenschutzerklärung
            </Link>
            .
          </P>
        </div>
        <div className="sm:col-span-2 sm:flex sm:justify-end">
          <button
            type="submit"
            className="mt-2 inline-flex w-full items-center justify-center border border-transparent bg-brand-green px-6 py-3 text-base font-medium text-brand-gray shadow-sm hover:bg-brand-green-dark focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 sm:w-auto"
          >
            Datenlöschung beantragen
          </button>
        </div>
      </form>
    </>
  );
};

export default DeleteAppUser;
