// Packages
import React from 'react';

// Components
import Layout from '~components/layouts/Default';

// Types
import type { SiteMetadata, Location } from '~types/Gatsby';
import Container from '~components/common/Container';
import PageHeader from '~components/common/PageHeader';
import DeleteAppUser from '~components/forms/DeleteAppUser';

interface DataProps {
  site: {
    siteMetadata: SiteMetadata;
  };
}

export interface ContentCMS {
  id: string;
  title: string;
  markdown: string;
}

export interface Content extends ContentCMS {}

export default function Page({ location }: { data: DataProps; location: Location }) {
  return (
    <Layout location={location} title="Entspannungshelden App - Anfrage zur Datenlöschung" desc="">
      <PageHeader
        title="Anfrage zur Datenlöschung"
        breadcrumb={[
          { title: 'App', link: '/app/', current: false },
          { title: 'Rechtliches', link: '/app/rechtliches', current: false },
          { title: 'Anfrage zur Datenlöschung', link: '/app/rechtliches/user-delete', current: true }
        ]}
      />
      <Container>
        <div className="mx-auto mt-6 max-w-2xl">
          <p>
            Mit diesem Formular kannst du eine Anfrage stellen, um deine Daten löschen zu lassen. <b>Du hast auch die Möglichkeit, den vollautomatischen Prozess zu nutzen, indem du dich in der App anmeldest</b> und deinen Datensatz direkt
            löschst. Mit dem automatischen Verfahren werden sofort all deine Daten gelöscht.{' '}
          </p>
          <DeleteAppUser />
        </div>
      </Container>
    </Layout>
  );
}
